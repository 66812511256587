// Importing necessary React and Material UI components
import React, { useCallback, useRef, useState, useEffect } from 'react';
import SongCard from "../../Cards/SongCard/SongCard";
import SongDetailDialog from "../../SongDetailDialog/SongDetailDialog";
import { Box, Grid, Container } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

// Interface to define the shape of a song object
interface Song {
  isrc: string;
  title: string;
  artist: string;
  year: number;
  streamCount: number;
  weeklyStreamCount: number;
  coverArtUrl: string;
  releaseDate: string;
  spotifyId: string;
  rank: number;
  globalRank: number;
  yearlyRank: number;
  globalTrendRank: number;  // Make sure this matches what's used in the API response
  yearlyTrendRank: number;  // Make sure this matches what's used in the API response
  dataPointsCount: number;
}

// Interface for the shape of a song object as received from the API
interface ApiSong {
  isrc: string;
  title: string;
  artist: string;
  year: number;
  stream_count: number;
  weekly_stream_count: number;
  cover_art_url: string;
  release_date: string;
  spotify_id: string;
  rank: number;
  global_rank: number;
  yearly_rank: number;
  global_trend_rank: number;
  yearly_trend_rank: number;
  data_points_count: number;
}

// Interface for the API response shape
interface ApiResponse {
  total_pages: number;
  total_songs_count: number;
  songs: ApiSong[];
}

// Props interface for the CardList component
interface CardListProps {
  listId: string;
  searchTerm: string;
  artistSearchTerm: string;
  displayDetails: string[];
  selectedYear: number | null;
  orderByStreamGain?: boolean;
  onTotalSongsCountChange?: (count: number) => void; // Add this line
}


// The main CardList component
const CardList: React.FC<CardListProps> = ({
  listId,
  searchTerm,
  artistSearchTerm,
  displayDetails,
  selectedYear,
  orderByStreamGain,
  onTotalSongsCountChange, // Add this line
}) => {
  // State for managing the selected song and dialog open state
  const [selectedSong, setSelectedSong] = React.useState<Song | null>(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  // States to manage songs data, pagination, loading and "load more" feature
  const [songs, setSongs] = React.useState<Song[]>([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const lastFetchParams = useRef({ page: 1, searchTerm: '', artistSearchTerm: '' });



  
  // Handler for opening the song detail dialog upon card click
  const handleCardClick = (song: Song) => {
    setSelectedSong(song);
    setIsDialogOpen(true);
  };

  // Handler for closing the song detail dialog
  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedSong(null);
  };

  const fetchSongs = async (
    page: number,
    term: string,
    artistTerms: string[],
    selectedYear?: number | null,
    orderByStreamGain?: boolean,
    isNewSearch = false // Add flag to indicate whether it's a new search
  ) => {
    // Check if a fetch operation is already in progress or if it's the same request as the last one
    const fetchIdentifier = {
      page,
      searchTerm: term,
      artistSearchTerm: artistTerms.join(','),
      selectedYear: selectedYear ?? 'all', // Use 'all' as a default value if no year is selected
      orderByStreamGain: orderByStreamGain ?? false, // Explicitly include orderByStreamGain in the identifier
    };

    const serializedFetchIdentifier = JSON.stringify(fetchIdentifier);
    if (isFetching || serializedFetchIdentifier === JSON.stringify(lastFetchParams.current)) {
      // Early return if already fetching or if the request parameters haven't changed
      return;
    }

    // Set up for new fetch
    setIsFetching(true);
    lastFetchParams.current = fetchIdentifier; // Update the last fetch parameters

    if (isNewSearch) {
      setSongs([]); // Clear the songs list if it's a new search
      setPage(1); // Reset page to 1 for new search (if necessary)
    }

    setLoading(true); // Indicate loading state

    // Construct query parameters
    const searchTermParam = term ? `&searchTerm=${encodeURIComponent(term)}` : "";
    const artistSearchTermsParam = artistTerms.map(artist => `&artistSearchTerm=${encodeURIComponent(artist)}`).join("");
    const orderByParam = orderByStreamGain ? "&orderBy=stream_gain_7_days" : "";
    const yearParam = selectedYear ? `&year=${selectedYear}` : "";

    // Fetch data from the API
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Replace with your actual API base URL
    const response = await fetch(
      `${baseURL}/api/latest-stream-count/?page=${page}${yearParam}${searchTermParam}${artistSearchTermsParam}${orderByParam}`
    );

    const data: ApiResponse = await response.json();

    // Filter out invalid songs and map them to the Song interface
    const validSongs = data.songs.filter(song => song.stream_count != null && song.global_rank != null)
                                .map((song: ApiSong): Song => ({
                                    isrc: song.isrc,
                                    title: song.title,
                                    artist: song.artist,
                                    year: song.year,
                                    streamCount: song.stream_count, // Map this from stream_count
                                    weeklyStreamCount: song.weekly_stream_count,
                                    coverArtUrl: song.cover_art_url, // Map this from cover_art_url
                                    releaseDate: song.release_date,
                                    spotifyId: song.spotify_id,
                                    rank: song.rank,
                                    globalRank: song.global_rank,
                                    yearlyRank: song.yearly_rank,
                                    globalTrendRank: song.global_trend_rank,
                                    yearlyTrendRank: song.yearly_trend_rank,
                                    dataPointsCount: song.data_points_count,
                                }));

    // Update the state with filtered and mapped songs
    setSongs(prevSongs => isNewSearch ? validSongs : [...prevSongs, ...validSongs]);
    setHasMore(page < data.total_pages);

    // Optionally, call the callback with the total songs count
    if (onTotalSongsCountChange) {
        onTotalSongsCountChange(data.total_songs_count);
    }

    // Reset loading and fetching indicators
    setLoading(false);
    setIsFetching(false);

  };




// Function to remove duplicate songs from the fetched data
const removeDuplicates = (data: Song[]) => {
  const result: Song[] = [];
  const map = new Map();
  for (const item of data) {
    const key = `${item.artist}_${item.streamCount}_${item.year}`;
    if (!map.has(key)) {
      map.set(key, true);
      result.push(item);
    }
  }
  return result;
};


  

    // For initial load or when filters change
    useEffect(() => {
      fetchSongs(1, searchTerm, artistSearchTerm.split(",").filter(Boolean), selectedYear, orderByStreamGain, true); // Pass true for isNewSearch
    }, [selectedYear, searchTerm, artistSearchTerm, orderByStreamGain]);

    // For pagination, no need to pass isNewSearch as it's false by default
    useEffect(() => {
      if (page !== 1) {
        fetchSongs(page, searchTerm, artistSearchTerm.split(",").filter(Boolean), selectedYear, orderByStreamGain);
      }
    }, [page]);



  // Creating an Intersection Observer to load more songs when the last song element comes into view
  const observer = React.useRef<IntersectionObserver | null>(null);
  const lastSongElementRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(prevPage => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore, page]
  );


  const loadMoreTriggerIndex = songs.length > 4 ? songs.length - 5 : 0;

  const loadMoreRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(prevPage => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, songs.length]
  );

  // The render part of the CardList component
  return (
    <Container
      maxWidth={false}
      sx={{
        padding: 0, // Remove default padding
        margin: 0,
        display: "flex",
        justifyContent: "center",
        "@media (min-width: 900px)": {
          maxWidth: "80%",
          margin: "0 auto",
        },
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={0} justifyContent="center">


        {songs.map((song, index) => {
          let gridSize = { xs: 12, sm: 12, md: 4, lg: 10 };
          // Check if the current song is 4 positions before the last
          const isLoadMoreTrigger = index === loadMoreTriggerIndex;

          return (
            <Grid item {...gridSize} key={song.isrc}>
              <SongCard
                ref={isLoadMoreTrigger ? loadMoreRef : null}
                song={song}
                displayDetails={displayDetails}
                selectedYear={selectedYear}
                orderByStreamGain={orderByStreamGain}
                onClick={() => handleCardClick(song)}
              />
            </Grid>
          );
          })}



        </Grid>
      </Box>
      <SongDetailDialog
        song={selectedSong}
        open={isDialogOpen}
        onClose={handleDialogClose}
        chosenYear={selectedYear}
      />
    </Container>
  );
};

export default CardList;
